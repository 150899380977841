

import { Delete, Edit } from "@element-plus/icons-vue";
import { client } from '@/client';
import { ElMessage, ElMessageBox } from 'element-plus';
import {  AdvertData } from "@/shared/types/AdvertData";
import { defineComponent } from 'vue';
export default defineComponent({
  name: "ad-index",
  setup() {
    return {
      Edit,
      Delete,
    };
  },
  data: () => {
    return {
      keywords: "",
      tableData: [] as AdvertData[],
      multipleSelection: [],
      count: 0,
      cur: 1,
    };
  },
  computed: {},
  created() {
    this.search();
  },
  methods: {
    //查询
    async search() {
      try {
        let res = await client.callApi("advert/List",{cur:this.cur,keywords:this.keywords});
        if (res.isSucc) {
          this.tableData = [...res.res.list];
          this.count = res.res.count;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //翻页
    handleCurrentChange(e: number) {
      this.cur = e;
      this.search();
    },

    toggleSelection(rows: any[]) {
          let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    handleSelectionChange(val: never[]) {
      this.multipleSelection = val;
    },

    //跳转到编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "ad-edit", params: { id: id } });
    },

    //删除文章
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
        let res = await client.callApi("advert/Del",{id:id});
        if (res.isSucc) {
          ElMessage({
            message: "删除成功 ^_^",
            type: "success",
          });
          this.search();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
