import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "mr-5"
}
const _hoisted_4 = {
  key: 1,
  class: "mr-5"
}
const _hoisted_5 = {
  key: 2,
  class: "mr-5"
}
const _hoisted_6 = {
  key: 3,
  class: "mr-5"
}
const _hoisted_7 = {
  key: 4,
  class: "mr-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, {
      type: "flex",
      justify: "end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          class: "c-fff add-btn",
          to: "/ad/add"
        }, {
          default: _withCtx(() => [
            _createTextVNode("新增")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_table, {
      ref: "multipleTable",
      data: _ctx.tableData,
      "tooltip-effect": "dark",
      "row-key": "id",
      size: "small",
      onSelectionChange: _ctx.handleSelectionChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, { type: "selection" }),
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "编号",
          width: "60"
        }),
        _createVNode(_component_el_table_column, {
          prop: "title",
          label: "标题"
        }),
        _createVNode(_component_el_table_column, {
          prop: "mark",
          label: "标识"
        }),
        _createVNode(_component_el_table_column, {
          prop: "platform",
          label: "平台"
        }, {
          default: _withCtx((scope) => [
            (scope.row.platform.includes('1'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "PC"))
              : _createCommentVNode("", true),
            (scope.row.platform.includes('2'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "H5"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "position",
          label: "位置"
        }, {
          default: _withCtx((scope) => [
            (scope.row.position.includes('1'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "首页"))
              : _createCommentVNode("", true),
            (scope.row.position.includes('2'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "频道页"))
              : _createCommentVNode("", true),
            (scope.row.position.includes('3'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "列表"))
              : _createCommentVNode("", true),
            (scope.row.position.includes('4'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "详情"))
              : _createCommentVNode("", true),
            (scope.row.position.includes('5'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "单页"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "createdAt",
          label: "发布时间",
          width: "160"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.createdAt.toLocaleString()), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "status",
          label: "状态"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.status == 1 ? "显示" : "隐藏"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          fixed: "right",
          label: "操作"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              icon: _ctx.Edit,
              circle: "",
              onClick: ($event: any) => (_ctx.toEdit(scope.row))
            }, null, 8, ["icon", "onClick"]),
            _createVNode(_component_el_button, {
              icon: _ctx.Delete,
              circle: "",
              onClick: ($event: any) => (_ctx.handleDel(scope.row))
            }, null, 8, ["icon", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "onSelectionChange"]),
    _createVNode(_component_el_row, {
      type: "flex",
      class: "mt-20 align-c",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_pagination, {
          background: "",
          layout: "prev, pager, next",
          onCurrentChange: _ctx.handleCurrentChange,
          "pager-size": 10,
          total: _ctx.count,
          "hide-on-single-page": ""
        }, null, 8, ["onCurrentChange", "total"])
      ]),
      _: 1
    })
  ]))
}